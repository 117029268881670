import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkAccessReport } from '../../../actions/auth';
import { routesStore } from '../../../stores/initStores';


// Отчеты Офис

import OfficeGeneralReportMoneyMovement from './general/reportMoneyMovement';
import OfficeGeneralReportCashDocs from './general/reportCashDocs';
import OfficeGeneralReportCashDocForDay from './general/reportCashDocForDay';
import OfficeGeneralReportTotalSales from './general/reportTotalSales';
import OfficeGeneralReportCashbackTransactions from './general/reportCashbackTransactions';
import OfficeGeneralReportFuelsGoodsPaytypes from './general/reportFuelsGoodsPaytypes';
import OfficeGeneralReportLottoPayTransactions from './general/reportLottoPayTransactions';
import OfficeGeneralReportBankTransactionsDiff from './general/reportBankTransactionsDiff';
import OfficeGeneralReportTerminalsInfo from './general/reportTerminalsInfo';
import OfficeGeneralReportCashDocsDiff from './general/reportCashDocsDiff';
import OfficeGeneralReportNotActivityStation from './general/reportNotActivityStation';
import OfficeGeneralReportShiftsChange from './general/reportShiftsChange';
import OfficeGeneralReportTotalSales2 from './general/reportTotalSales2';
import OfficeGeneralReportDocsTaxes from './general/reportDocsTaxes';
import OfficeGeneralReportCheckCopy from './general/reportCheckCopy';
import OfficeGeneralReportDeductionZSUByClients from './general/reportDeductionZSUByClients'; 
import OfficeGeneralReportDeductionZSUTotalStations from './general/reportDeductionZSUTotalStations'; 
import OfficeGeneralReportBankTransactionsCancelPayment from './general/reportBankTransactionsCancelPayment';
import OfficeGeneralReportVisitsUsersStations from './general/reportVisitsUsersStations';

import OfficeFuelReportComplex from './fuel/reportComplex';
import OfficeFuelReport17NP from './fuel/report17NP';
import OfficeFuelReportFuelsChecks from './fuel/reportFuelsChecks';
import OfficeFuelReportFuelsPaytypes from './fuel/reportFuelsPaytypes';
import OfficeFuelReportFuelSalessCash from './fuel/reportFuelSalesCash';
import OfficeFuelReportFuelsTankSaldos from './fuel/reportFuelsTankSaldos';
import OfficeFuelReportFuelsDiscounts from './fuel/reportFuelsDiscounts';
import OfficeFuelReportFuelsSalesByHours from './fuel/reportFuelsSalesByHours';
import OfficeFuelReportFiscalRegister from './fuel/reportFiscalRegister';
import OfficeFuelReportFuelsRealRest from './fuel/reportFuelsRealRest';
import OfficeFuelReportFuelSalesGrid from './fuel/reportFuelSalesGrid';
import OfficeFuelReportFuelCompareSales from './fuel/reportFuelCompareSales';
import OfficeFuelReportFuelsPrices from './fuel/reportFuelsPrices';
import OfficeFuelReportFuelIncomes from './fuel/reportFuelIncomes';
import OfficeFuelReportFuelRestGauges from './fuel/reportFuelRestGauges';
import OfficeFuelReportSpillPrice from './fuel/reportSpillPrice';
import OfficeFuelReportFuelSalesDailyGrid from './fuel/reportFuelSalesDailyGrid';
import OfficeFuelReportFuelFeedRate from './fuel/reportFuelFeedRate';
import OfficeFuelReportFuelIncomesSales from './fuel/reportFuelIncomesSales';
import OfficeFuelReportFuelSalesAmountChecks from './fuel/reportFuelSalesAmountChecks';
import OfficeFuelReportFuelsCheckCounters from './fuel/reportFuelsCheckCounters';
import OfficeFuelReportFuelsChecksCount from './fuel/reportFuelsChecksCount';
import OfficeFuelReportFuelsFullTanks from './fuel/reportFuelsFullTanks';
import OfficeFuelReportFuelsPaytypes2 from './fuel/reportFuelsPaytypes2';
import OfficeFuelReportLoyaltyFuelsChecks from './fuel/reportLoyaltyFuelsChecks';
import OfficeFuelReportFuelInventory from './fuel/reportFuelInventory';
import OfficeFuelReportFuelInventoryMonth from './fuel/reportFuelInventoryMonth';
import OfficeFuelReportFuelSalesBrsm2 from './fuel/reportFuelSalesBrsm2';
import OfficeFuelReportRestManualEdit from './fuel/reportRestManualEdit';
import OfficeFuelReportFuelsTankAlarm from './fuel/reportFuelsTankAlarm';
import OfficeFuelReportFuelsDiscountsSummary from './fuel/reportFuelsDiscountsSummary';

import OfficeFuelReportReportTechSpills from './tspill/reportTechSpills';
import OfficeFuelReportTechSpillsTerminalDetailed from './tspill/reportTechSpillsTerminalDetailed';
import OfficeFuelReportReportTechSpillsWithSum from './tspill/reportTechSpillsWithSum';
import OfficeFuelReportReportTechSpillsWithSumDiscounts from './tspill/reportTechSpillsWithSumDiscounts';
import OfficeFuelReportTechSpillsDifferences from './tspill/reportTechSpillsDifferences';
import OfficeFuelReportTechSpillsNormed from './tspill/reportTechSpillsNormed';
import OfficeFuelReportTechSpillsTerminalDetailedRepair from './tspill/reportTechSpillsTerminalDetailedRepair';
import OfficeFuelReportTechSpillsHistoryLog from './tspill/reportTechSpillsHistoryLog';

import OfficeFuelReportWSSpills from './wsspill/reportWsSpills';
import OfficeFuelReportWSSpillsDetailed from './wsspill/reportWsSpillsDetailed';
import OfficeFuelReportWSSpillsDifferences from './wsspill/reportWsSpillsDifferences';
import OfficeCardsReportEngineWSBalance from './wsspill/reportWSBalance';
import OfficeCardsReportEngineWSAutoWithdraw from './wsspill/reportWSAutoWithdraw';

import OfficeTalonsReportAbuseTalons from './talons/reportAbuseTalons';
import OfficeTalonsReportFuelsChecksTalons from './talons/reportFuelsChecksTalons';
import OfficeTalonsReportCheckUseTalons from './talons/reportCheckUseTalons';
import OfficeTalonsReportTalonsActive from './talons/reportTalonsActive';
import OfficeTalonsReportTalonsPayOff from './talons/reportTalonsPayOff';
import OfficeTalonsReportTalonsInfo from './talons/reportTalonsInfo';

import OfficeGoodsReportGoodsChecks from './goods/reportGoodsChecks';
import OfficeGoodsReportGoodsSalesTaxes from './goods/reportGoodsSalesTaxes';
import OfficeGoodsReportGoodsSalesByHours from './goods/reportGoodsSalesByHours';
import OfficeGoodsReportSalesGoodsFilter from './goods/reportSalesGoodsFilter';
import OfficeGoodsReportSalesWine from './goods/reportSalesWine';
import OfficeGoodsReportSalesWasher from './goods/reportSalesWasher';
import OfficeGoodsReportSalesGlovo from './goods/reportSalesGlovo';

import OfficeCardsReportTaxiTransactions from './cards/reportTaxiTransactions';
import OfficeCardsReportTaxiAvgSpill from './cards/reportTaxiAvgSpill';
import OfficeCardsReportTaxiRests from './cards/reportTaxiRests';
import OfficeCardsReportEngineTransactions from './cards/reportEngineTransactions';
import OfficeCardsReportTNPPortEngine from './cards/reportTNPPortEngine';
import OfficeCardsReportEngineBalance from './cards/reportEngineBalance';
import OfficeCardsReportRevisePortEngine from './cards/reportRevisePortEngine';
import OfficeCardsReportRevisePortEngineFuels from './cards/reportRevisePortEngineFuels';
import OfficeCardsReportFuelWallet from './cards/reportFuelWallet';
import OfficeCardsReportRevisePortTaxi from './cards/reportRevisePortTaxi';
import OfficeCardsReportRevisePortTaxiFuels from './cards/reportRevisePortTaxiFuels';
import OfficeCardsReportRevisePortEngineVS from './cards/reportRevisePortEngineVS';
import OfficeCardsReportRevisePortEngineFuelsVS from './cards/reportRevisePortEngineFuelsVS';
import OfficeCardsProblemBackgroundTrans from './cards/reportProblemBackgroundTrans';
import OfficeCardsSalesFuelsDiffCardsTrans from './cards/reportSalesFuelsDiffCardsTrans';
import OfficeCardsReportWogSpills from './cards/reportWogSpills';
import OfficeCardsReportWogProblemTrans from './cards/reportWogProblemTrans';

import OfficeWalletReportWalletReplenish from './wallet/reportWalletReplenish';
import OfficeWalletReportWalletBalances from './wallet/reportWalletBalances';

class OfficeReports extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isAccess : false
        }
    }

    onAccess() {
        let _isAccess = checkAccessReport(this.props);
        if(_isAccess !== this.state.isAccess) {
          this.setState({
            isAccess : _isAccess
          })
        }
      }
  
    componentDidMount() {
        this.onAccess();
      }
  
    componentDidUpdate() {
        this.onAccess(); 
    }

    onOfficeReports() {

        const _path = this.props.location.pathname;
        const _route = routesStore.find(item => item.path === _path);

        console.log('onOfficeReports');
        
        if(_route) {
            switch(_route.id) {
                case 33: return <OfficeGeneralReportMoneyMovement />;
                case 34: return <OfficeGeneralReportCashDocs />;
                case 35: return <OfficeGeneralReportCashDocForDay />;
                case 37: return <OfficeGeneralReportTotalSales />;     
                case 58: return <OfficeGeneralReportCashbackTransactions/>;  
                case 59: return <OfficeGeneralReportFuelsGoodsPaytypes/>;                 
                case 60: return <OfficeGeneralReportLottoPayTransactions/>;   
                case 61: return <OfficeGeneralReportBankTransactionsDiff/>; 
                case 66: return <OfficeGeneralReportTerminalsInfo />;      
                case 82: return <OfficeGeneralReportCashDocsDiff/>;
                case 83: return <OfficeGeneralReportNotActivityStation/>;
                case 84: return <OfficeGeneralReportShiftsChange/>;
                case 85: return <OfficeGeneralReportTotalSales2/>;
                case 86: return <OfficeGeneralReportDocsTaxes/>;
                case 87: return <OfficeGeneralReportCheckCopy/>;
                case 120: return <OfficeGeneralReportDeductionZSUByClients/>;
                case 121: return <OfficeGeneralReportDeductionZSUTotalStations/>;
                case 126: return <OfficeGeneralReportBankTransactionsCancelPayment/>;
                case 127: return <OfficeGeneralReportVisitsUsersStations/>;

                case 36: return <OfficeFuelReportComplex />;
                case 41: return <OfficeFuelReport17NP />;
                case 42: return <OfficeFuelReportFuelsChecks />;
                case 43: return <OfficeFuelReportFuelsPaytypes />;
                case 44: return <OfficeFuelReportFuelSalessCash />;
                case 45: return <OfficeFuelReportFuelsTankSaldos />;
                case 46: return <OfficeFuelReportFuelsDiscounts />;
                case 47: return <OfficeFuelReportFuelsSalesByHours />;
                case 48: return <OfficeFuelReportFiscalRegister />;
                case 49: return <OfficeFuelReportFuelsRealRest />;
                case 50: return <OfficeFuelReportFuelSalesGrid />;
                case 51: return <OfficeFuelReportFuelCompareSales />;
                case 52: return <OfficeFuelReportFuelsPrices />;
                case 53: return <OfficeFuelReportFuelIncomes />;
                case 62: return <OfficeFuelReportFuelRestGauges/>;
                case 70: return <OfficeFuelReportSpillPrice/>;
                case 73: return <OfficeFuelReportFuelSalesDailyGrid/>;
                case 88: return <OfficeFuelReportFuelFeedRate/>;
                case 89: return <OfficeFuelReportFuelIncomesSales/>;
                case 90: return <OfficeFuelReportFuelSalesAmountChecks/>;
                case 91: return <OfficeFuelReportFuelsCheckCounters/>;
                case 92: return <OfficeFuelReportFuelsChecksCount/>;
                case 93: return <OfficeFuelReportFuelsFullTanks/>;
                case 94: return <OfficeFuelReportFuelsPaytypes2/>;
                case 95: return <OfficeFuelReportLoyaltyFuelsChecks/>;
                case 96: return <OfficeFuelReportFuelInventory/>;
                case 97: return <OfficeFuelReportFuelInventoryMonth/>;
                case 98: return <OfficeFuelReportFuelSalesBrsm2/>;
                case 99: return <OfficeFuelReportRestManualEdit/>;
                case 103: return <OfficeFuelReportReportTechSpills/>;
                case 113: return <OfficeFuelReportFuelsTankAlarm/>;
                case 116: return <OfficeFuelReportFuelsDiscountsSummary/>;

                case 104: return <OfficeFuelReportTechSpillsTerminalDetailed/>;
                case 105: return <OfficeFuelReportReportTechSpillsWithSum/>;
                case 106: return <OfficeFuelReportReportTechSpillsWithSumDiscounts/>;
                case 107: return <OfficeFuelReportTechSpillsDifferences/>;
                case 108: return <OfficeFuelReportTechSpillsNormed/>;
                case 111: return <OfficeFuelReportTechSpillsTerminalDetailedRepair/>;
                case 128: return <OfficeFuelReportTechSpillsHistoryLog/>;

                case 123: return <OfficeFuelReportWSSpills/>;
                case 124: return <OfficeFuelReportWSSpillsDetailed/>;
                case 125: return <OfficeFuelReportWSSpillsDifferences/>;
                case 129: return <OfficeCardsReportEngineWSBalance/>;
                case 130: return <OfficeCardsReportEngineWSAutoWithdraw/>;

                case 54: return <OfficeTalonsReportAbuseTalons />;
                case 55: return <OfficeTalonsReportFuelsChecksTalons />;
                case 56: return <OfficeTalonsReportCheckUseTalons/>;
                case 63: return <OfficeTalonsReportTalonsActive/>;
                case 64: return <OfficeTalonsReportTalonsPayOff/>;
                case 65: return <OfficeTalonsReportTalonsInfo/>;

                case 38: return <OfficeGoodsReportGoodsChecks />;
                case 39: return <OfficeGoodsReportGoodsSalesTaxes />;
                case 40: return <OfficeGoodsReportGoodsSalesByHours />;
                case 100: return <OfficeGoodsReportSalesGoodsFilter/>;
                case 101: return <OfficeGoodsReportSalesWine/>;
                case 102: return <OfficeGoodsReportSalesWasher/>;
                case 117: return <OfficeGoodsReportSalesGlovo/>;

                case 69: return <OfficeCardsReportTaxiTransactions/>;
                case 71: return <OfficeCardsReportTaxiAvgSpill/>;
                case 72: return <OfficeCardsReportTaxiRests/>;
                case 74: return <OfficeCardsReportEngineTransactions/>;
                case 75: return <OfficeCardsReportTNPPortEngine/>;
                case 76: return <OfficeCardsReportEngineBalance/>;
                case 77: return <OfficeCardsReportRevisePortEngine/>;
                case 78: return <OfficeCardsReportRevisePortEngineFuels/>;
                case 79: return <OfficeCardsReportFuelWallet/>;
                case 80: return <OfficeCardsReportRevisePortTaxi/>;
                case 81: return <OfficeCardsReportRevisePortTaxiFuels/>;
                case 114: return <OfficeCardsReportRevisePortEngineVS/>;
                case 115: return <OfficeCardsReportRevisePortEngineFuelsVS/>;
                case 118: return <OfficeCardsProblemBackgroundTrans/>;
                case 119: return <OfficeCardsSalesFuelsDiffCardsTrans/>;
                case 134: return <OfficeCardsReportWogSpills />;
                case 135: return <OfficeCardsReportWogProblemTrans/>;

                case 133: return <OfficeWalletReportWalletReplenish/>;
                case 132: return <OfficeWalletReportWalletBalances/>;

                default:
                    break;
            }            
        }
        
        return window.location.replace('/404');
    }

    render() {        
        return(            
            <div hidden={!this.state.isAccess}>
                {this.onOfficeReports()}
            </div>
        );
    }

}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user      
    }),
    dispatch => ({
    })
  )(OfficeReports);